import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import ReactModal from "react-modal";
import AuthContext from "../context/AuthContext";
import {
  getGenericEmptyObject,
  getTypeOptionsBasedOnPermissions,
  isDatacatalogType,
  isPackingType,
} from "../helpers";
import { GpnEditCreateForm } from "./specific_edit_create_forms/gpn_tcc_forms/GpnEditCreateForm";
import { DatacatalogEditCreateForm } from "./specific_edit_create_forms/datacatalog_forms/DatacatalogEditCreateForm";
import { PmHierEditCreateForm } from "./specific_edit_create_forms/pmhier_forms/PmHierEditCreateForm";

const EditCreateForm = ({
  isEdit,
  item,
  updateCreateItem,
  errors,
  setErrors,
}: {
  isEdit: boolean;
  item: any;
  updateCreateItem: any;
  errors: any;
  setErrors: any;
}) => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const [typeOptions, setTypeOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [typeSelected, setTypeSelected] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [newItem, setNewItem] = useState<any>(item);
  const [activeForm, setActiveForm] = useState<any>(null);

  const onTypeChange = (e: any) => {
    setTypeSelected(e);
    setNewItem({ ...getGenericEmptyObject(), type: e.value });
    setErrors({});
  };

  const getFormComponent = (type: string) => {
    if (isDatacatalogType(type))
      return (
        <DatacatalogEditCreateForm
          isEdit={isEdit}
          item={newItem}
          updateCreateItem={updateCreateItem}
          errors={errors}
        />
      );
    if (isPackingType(type))
      return (
        <PmHierEditCreateForm
          isEdit={isEdit}
          item={newItem}
          updateCreateItem={updateCreateItem}
          errors={errors}
        />
      );
    // if (type === "Tupperware Consumer Code")
    //   return <TccEditCreateForm isEdit={isEdit} item={newItem} updateCreateItem={updateCreateItem} errors={errors} />;
    if (type === "Global Product")
      return (
        <GpnEditCreateForm
          isEdit={isEdit}
          item={newItem}
          updateCreateItem={updateCreateItem}
          errors={errors}
        />
      );

    return null;
  };

  useEffect(() => {
    ReactModal.setAppElement("#root");
    setTypeOptions(getTypeOptionsBasedOnPermissions(hasPermission));
    if (item) {
      setTypeSelected({ label: item.type, value: item.type });
      setNewItem(item);
    }
  }, [item, isEdit]);

  useEffect(() => {
    setActiveForm(getFormComponent(newItem.type));
  }, [newItem]);

  return (
    <div className="py-3 mb-2">
      <div className="row form-row mb-4">
        <div className="col-md-4 form-group">
          <label className="form-label">Type</label>
          <Select
            onChange={onTypeChange}
            isDisabled={isEdit}
            options={typeOptions}
            value={typeSelected}
            classNamePrefix="react-select"
            className={errors.type ? "is-invalid" : ""}
            placeholder=""
            components={{
              IndicatorSeparator: () => null,
              ClearIndicator: () => null,
            }}
          />
        </div>
      </div>
      {activeForm}
    </div>
  );
};

export default EditCreateForm;
