import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import { useEffect, useState } from "react";
import { IData } from "../../../types/data.interface";
import { formatDatacalogIntoOption, getParentType } from "../../../helpers";

export const DatacatalogFilterForm = ({
    data,
    typeSelected,
    setFilteredValues,
}: {
    data: IData[];
    typeSelected: string;
    setFilteredValues: any;
}) => {
    const parentType: string = "Category";

    const [datacatalogValue, setDatacatalogValue] = useState<{
        label: string;
        value: IData;
    } | null>(null);
    const [datacatalogParentValue, setDatacatalogParentValue] = useState<{
        label: string;
        value: IData;
    } | null>(null);

    const [datacatalogValueOptions, setDatacatalogValueOptions] = useState<{
        label: string;
        value: IData;
    }[]>();
    const [datacatalogParentValueOptions, setDatacatalogParentValueOptions] = useState<{
        label: string;
        value: IData;
    }[]>();
    const [showHierOptions, setShowHierOptions] = useState(false);

    useEffect(() => {
        setDatacatalogValue(null);
        setDatacatalogParentValue(null);
        setDatacatalogValueOptions(data.filter((item) => item.type === typeSelected).map(formatDatacalogIntoOption));
        setDatacatalogParentValueOptions(data.filter((item) => item.type === parentType).map(formatDatacalogIntoOption));

        setShowHierOptions(getParentType(typeSelected).length > 0);
    }, [typeSelected]);

    useEffect(() => {
        let filteredValues: IData[] = data.filter((item) => item.type === typeSelected);
        if (datacatalogValue) {
            filteredValues = filteredValues.filter((item) => item.id === datacatalogValue.value.id);
        }

        if (datacatalogParentValue) {
            filteredValues = filteredValues.filter((item) => {
                if (item.parent) {
                    return item.parent.id === datacatalogParentValue.value.id;
                }
                return false;
            });
        }

        setFilteredValues(filteredValues);
    }, [datacatalogValue, datacatalogParentValue]);

    const handleDatacatalogValue = (e: any) => {
        if (e) {
            setDatacatalogValue(e);
            const parent: IData = e.value.parent;
            if (parent) {
                setDatacatalogParentValue(formatDatacalogIntoOption(parent));
            }
        } else {
            setDatacatalogValue(null);
            setDatacatalogParentValueOptions(data.filter((item) => item.type === parentType).map(formatDatacalogIntoOption));
        }
    };

    const handleDatacatalogParentValue = (e: any) => {
        if (e) {
            setDatacatalogValue(null);
            setDatacatalogParentValue(formatDatacalogIntoOption(e.value));
            setDatacatalogValueOptions(data.filter((item) => item.type === typeSelected && item.parent?.id === e.value.id).map(formatDatacalogIntoOption));
        } else {
            setDatacatalogParentValue(null);
            setDatacatalogValue(null);
            setDatacatalogValueOptions(data.filter((item) => item.type === typeSelected).map(formatDatacalogIntoOption));
        }
    };

    return (
        <>
            <div className="row form-row align-items-end">
                <div className="col-md-10 form-group">
                    <label className="form-label">Code / Value</label>
                    <Select
                        onChange={handleDatacatalogValue}
                        options={datacatalogValueOptions}
                        value={datacatalogValue}
                        formatOptionLabel={OptionFormatter}
                        classNamePrefix="react-select"
                        placeholder=""
                        isClearable
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>
            </div>
            <div className="row align-items-end form-row">
                {showHierOptions && (
                    <>
                        <div className="col-md-3 form-group">
                            <label className="form-label">Hierarchy Type</label>
                            <Select
                                value={
                                    {
                                        value: parentType,
                                        label: parentType
                                    }
                                }
                                isDisabled
                                classNamePrefix="react-select"
                                placeholder=""
                                menuIsOpen={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-md-7 form-group">
                            <label className="form-label">Category Code / Value</label>
                            <Select
                                onChange={handleDatacatalogParentValue}
                                options={datacatalogParentValueOptions}
                                value={datacatalogParentValue}
                                classNamePrefix="react-select"
                                placeholder=""
                                isClearable
                                formatOptionLabel={OptionFormatter}
                                isSearchable
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}