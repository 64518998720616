import OptionFormatter from "../../OptionFormatter";
import { useEffect, useState } from "react";
import {
  IBrand,
  IData,
  IGlobalProduct,
  ILinePlanningStatusCondition,
} from "../../../types/data.interface";
import {
  formatBooleanIntoOption,
  formatBooleanOptions,
  formatBrandIntoOption,
  formatBrandOptions,
  formatCapacityUnitIntoOption,
  formatCapacityUnitOptions,
  formatDatacalogIntoOption,
  formatDatacatalogOptions,
  formatGlobalProductOptions,
  formatLifecycleFlagIntoOption,
  formatLifecycleFlagsOptions,
  formatLifecycleStatusIntoOption,
  formatLifecycleStatusOptions,
} from "../../../helpers";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useOutletContext } from "react-router-dom";
import {
  LIFECYCLE_STATUS_LABEL,
  PRODUCTION_STATUS_LABEL,
  SALES_STATUS_LABEL,
} from "../../../constants/labels";
import { Predicates } from "../../../libraries/predicates/predicates";
import { GPPersistentData } from "../../../types/persistent-page-filters";
import {
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../../../helpers/local-storage.helper";

export const GpnFilterForm = ({
  globalProducts,
  typeSelected,
  setFilteredValues,
  signalToClearFilters,
  sendFilteredDataToTable,
}: {
  globalProducts: IGlobalProduct[];
  typeSelected: string;
  setFilteredValues: any;
  signalToClearFilters: number;
  sendFilteredDataToTable: (
    typesSelected: string,
    filteredValues: any[]
  ) => void;
}) => {
  const { dataCatalog, brands, linePlanningStatusConditions } =
    useOutletContext<any>();

  const categoryOptions = dataCatalog.filter(
    (item: IData) => item.type === "Category"
  );
  const productLineOptions = dataCatalog.filter(
    (item: IData) => item.type === "Product Line"
  );
  const [subCategoryOptions, setSubCategoryOptions] = useState(
    dataCatalog.filter((item: IData) => item.type === "Sub-Category")
  );

  const [gpnValue, setGpnValue] = useState<{
    label: string;
    value: IGlobalProduct;
  } | null>(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "gpnValue",
      null
    )
  );

  const [categorySelected, setCategorySelected] = useState<IData | null>(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "categorySelected",
      null
    )
  );
  const [subCategorySelected, setSubCategorySelected] = useState<IData | null>(
    () =>
      retrieveValueFromLocalStorageObject<GPPersistentData>(
        "gp",
        "lastFilters",
        "subCategorySelected",
        null
      )
  );
  const [productLineSelected, setProductLineSelected] = useState<IData | null>(
    () =>
      retrieveValueFromLocalStorageObject<GPPersistentData>(
        "gp",
        "lastFilters",
        "productLineSelected",
        null
      )
  );
  const [alwaysOnSelected, setAlwaysOnSelected] = useState<boolean | null>(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "alwaysOnSelected",
      null
    )
  );
  const [brandSelected, setBrandSelected] = useState<IBrand | null>(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "brandSelected",
      null
    )
  );
  const [capacityValueSelected, setCapacityValueSelected] = useState<string>(
    () =>
      retrieveValueFromLocalStorageObject<GPPersistentData>(
        "gp",
        "lastFilters",
        "capacityValueSelected",
        ""
      )
  );
  const [capacityUnitSelected, setCapacityUnitSelected] = useState<
    string | null
  >(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "capacityUnitSelected",
      null
    )
  );
  const [productionLifecycleStatus, setProductionLifecycleStatus] = useState<
    string | null
  >(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "productionLifecycleStatus",
      null
    )
  );
  const [salesLifecycleStatus, setSalesLifecycleStatus] = useState<
    string | null
  >(() =>
    retrieveValueFromLocalStorageObject<GPPersistentData>(
      "gp",
      "lastFilters",
      "salesLifecycleStatus",
      null
    )
  );
  const [lifecycleStatusSelected, setLifecycleStatusSelected] =
    useState<ILinePlanningStatusCondition | null>(() =>
      retrieveValueFromLocalStorageObject<GPPersistentData>(
        "gp",
        "lastFilters",
        "lifecycleStatusSelected",
        null
      )
    );

  const [gpnValueOptions, setGpnValueOptions] = useState<
    {
      label: string;
      value: IGlobalProduct;
    }[]
  >();

  useEffect(() => {
    setGpnValue(null);
    setGpnValueOptions(formatGlobalProductOptions(globalProducts));
  }, [typeSelected, globalProducts]);

  useEffect(() => {
    if (isAnyValueSelected()) {
      sendFilteredDataToTable(
        typeSelected,
        filterGpsBasedOnValuesSelected(globalProducts)
      );
    }
  }, []);

  useEffect(() => {
    setFilteredValues(filterGpsBasedOnValuesSelected(globalProducts));

    saveObjectsToLocalStorage<GPPersistentData>({
      prefix: "gp",
      lastFilters: {
        gpnValue,
        categorySelected,
        subCategorySelected,
        productLineSelected,
        alwaysOnSelected,
        brandSelected,
        capacityValueSelected,
        capacityUnitSelected,
        productionLifecycleStatus,
        salesLifecycleStatus,
        lifecycleStatusSelected,
      },
    });
  }, [
    gpnValue,
    categorySelected,
    subCategorySelected,
    productLineSelected,
    alwaysOnSelected,
    brandSelected,
    capacityValueSelected,
    capacityUnitSelected,
    productionLifecycleStatus,
    salesLifecycleStatus,
    lifecycleStatusSelected,
  ]);

  const filterGpsBasedOnValuesSelected = (initialValues: IGlobalProduct[]) => {
    if (Predicates.isNotNullAndNotUndefined(gpnValue)) {
      initialValues = initialValues.filter(
        (item) => item.id === gpnValue.value.id
      );
    }

    if (Predicates.isNotNullAndNotUndefined(categorySelected)) {
      initialValues = initialValues.filter(
        (item) => item.sub_category?.parent?.id === categorySelected.id
      );
    }

    if (Predicates.isNotNullAndNotUndefined(subCategorySelected)) {
      initialValues = initialValues.filter(
        (item) => item.sub_category?.id === subCategorySelected.id
      );
    }

    if (Predicates.isNotNullAndNotUndefined(productLineSelected)) {
      initialValues = initialValues.filter(
        (item) => item.product_line?.id === productLineSelected.id
      );
    }

    if (Predicates.isNotNullAndNotUndefined(alwaysOnSelected)) {
      initialValues = initialValues.filter(
        (item) => item.is_always_on === alwaysOnSelected
      );
    }

    if (Predicates.isNotNullAndNotUndefined(brandSelected)) {
      initialValues = initialValues.filter(
        (item) => item.brand?.id === brandSelected.id
      );
    }

    if (capacityValueSelected.trim().length > 0) {
      initialValues = initialValues.filter(
        (item) => item.capacity_value === Number(capacityValueSelected.trim())
      );
    }

    if (Predicates.isNotNullAndNotUndefined(capacityUnitSelected)) {
      initialValues = initialValues.filter(
        (item) => item.capacity_uom === capacityUnitSelected
      );
    }

    if (Predicates.isNotNullAndNotUndefined(productionLifecycleStatus)) {
      initialValues = initialValues.filter(
        (item) => item.production_lifecycle_status === productionLifecycleStatus
      );
    }

    if (Predicates.isNotNullAndNotUndefined(salesLifecycleStatus)) {
      initialValues = initialValues.filter(
        (item) => item.sales_lifecycle_status === salesLifecycleStatus
      );
    }

    if (Predicates.isNotNullAndNotUndefined(lifecycleStatusSelected)) {
      initialValues = initialValues.filter(
        (item) =>
          item.line_planning_status ===
          lifecycleStatusSelected.line_planning_status
      );
    }

    return initialValues;
  };

  const isAnyValueSelected = () => {
    return (
      Predicates.isNotNullAndNotUndefined(gpnValue) ||
      Predicates.isNotNullAndNotUndefined(categorySelected) ||
      Predicates.isNotNullAndNotUndefined(subCategorySelected) ||
      Predicates.isNotNullAndNotUndefined(productLineSelected) ||
      Predicates.isNotNullAndNotUndefined(alwaysOnSelected) ||
      Predicates.isNotNullAndNotUndefined(brandSelected) ||
      capacityValueSelected.trim().length > 0 ||
      Predicates.isNotNullAndNotUndefined(capacityUnitSelected) ||
      Predicates.isNotNullAndNotUndefined(productionLifecycleStatus) ||
      Predicates.isNotNullAndNotUndefined(salesLifecycleStatus) ||
      Predicates.isNotNullAndNotUndefined(lifecycleStatusSelected)
    );
  };

  const handleGpnValue = (e: any) => {
    if (e) {
      setGpnValue(e);
    } else {
      setGpnValue(null);
    }
  };

  const handleCategoryValue = (e: any) => {
    if (e) {
      setCategorySelected(e.value);
      setSubCategorySelected(null);
      setSubCategoryOptions(
        dataCatalog.filter((item: IData) => item.parent?.id == e.value.id)
      );
    } else {
      setCategorySelected(null);
      setSubCategorySelected(null);
      setSubCategoryOptions(
        dataCatalog.filter((item: IData) => item.type === "Sub-Category")
      );
    }
  };

  const handleSubCategoryValue = (e: any) => {
    if (e) {
      setSubCategorySelected(e.value);
      setCategorySelected(e.value.parent);
    } else {
      setSubCategorySelected(null);
    }
  };

  const handleProductLineValue = (e: any) => {
    if (e) {
      setProductLineSelected(e.value);
    } else {
      setProductLineSelected(null);
    }
  };

  const handleAlwaysOnValue = (e: any) => {
    if (e) {
      setAlwaysOnSelected(e.value);
    } else {
      setAlwaysOnSelected(null);
    }
  };

  const handleBrandValue = (e: any) => {
    if (e) {
      setBrandSelected(e.value);
    } else {
      setBrandSelected(null);
    }
  };

  const handleCapacityValue = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setCapacityValueSelected(e.target.value);
    }
  };

  const handleCapacityUnitValue = (e: any) => {
    if (e) {
      setCapacityUnitSelected(e.value);
    } else {
      setCapacityUnitSelected(null);
    }
  };

  const handleProductionLifecycleStatus = (e: any) => {
    if (e) {
      setProductionLifecycleStatus(e.value);
    } else {
      setProductionLifecycleStatus(null);
    }
  };

  const handleSalesLifecycleStatus = (e: any) => {
    if (e) {
      setSalesLifecycleStatus(e.value);
    } else {
      setSalesLifecycleStatus(null);
    }
  };

  const handleLifecycleStatus = (e: any) => {
    if (e) {
      setLifecycleStatusSelected(e.value);
    } else {
      setLifecycleStatusSelected(null);
    }
  };

  const loadGpsBasedOnSearch = (input: string, callback: any) => {
    if (input.length < 3) {
      return callback([]);
    }

    const optionsToSearchFrom = gpnValueOptions;
    const optionsFound =
      optionsToSearchFrom
        ?.filter((gpOption) =>
          gpOption.label.toLowerCase().includes(input.toLowerCase())
        )
        .slice(0, 100) ?? [];
    callback(optionsFound);
  };

  useEffect(() => {
    if (signalToClearFilters > 0) {
      setGpnValue(null);
      setCategorySelected(null);
      setSubCategorySelected(null);
      setProductLineSelected(null);
      setAlwaysOnSelected(null);
      setBrandSelected(null);
      setCapacityValueSelected("");
      setCapacityUnitSelected(null);
      setProductionLifecycleStatus(null);
      setSalesLifecycleStatus(null);
      setLifecycleStatusSelected(null);

      setFilteredValues(globalProducts);
    }
  }, [signalToClearFilters]);

  return (
    <>
      <div className="row form-row align-items-end">
        <div className="col-md-12 form-group">
          <label className="form-label">Code / Name</label>
          <AsyncSelect
            onChange={handleGpnValue}
            defaultOptions={!gpnValue ? [] : [gpnValue]}
            loadOptions={loadGpsBasedOnSearch}
            noOptionsMessage={() => (
              <p className="mb-0">
                Search for the Code or Name of the desired Global Product
              </p>
            )}
            value={gpnValue}
            classNamePrefix="react-select"
            placeholder="(min 3 characters)"
            isClearable
            formatOptionLabel={OptionFormatter}
            isSearchable
            isLoading={gpnValueOptions?.length == 0}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>

      <div className="row form-row align-items-end">
        <div className="col-md-4 form-group">
          <label className="form-label">Category</label>
          <Select
            onChange={handleCategoryValue}
            value={
              categorySelected
                ? formatDatacalogIntoOption(categorySelected)
                : null
            }
            options={formatDatacatalogOptions(categoryOptions)}
            isSearchable
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">Sub-Category</label>
          <Select
            onChange={handleSubCategoryValue}
            value={
              subCategorySelected
                ? formatDatacalogIntoOption(subCategorySelected)
                : null
            }
            options={formatDatacatalogOptions(subCategoryOptions)}
            isSearchable
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">Product Line</label>
          <Select
            onChange={handleProductLineValue}
            value={
              productLineSelected
                ? formatDatacalogIntoOption(productLineSelected)
                : null
            }
            options={formatDatacatalogOptions(productLineOptions)}
            isSearchable
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>

      <div className="row form-row align-items-end">
        <div className="col-md-3 form-group">
          <label className="form-label">Always On</label>
          <Select
            onChange={handleAlwaysOnValue}
            formatOptionLabel={OptionFormatter}
            value={
              alwaysOnSelected != null
                ? formatBooleanIntoOption(alwaysOnSelected)
                : null
            }
            options={formatBooleanOptions()}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            // isLoading={isLoading}
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Brand</label>
          <Select
            onChange={handleBrandValue}
            formatOptionLabel={OptionFormatter}
            value={brandSelected ? formatBrandIntoOption(brandSelected) : null}
            options={formatBrandOptions(brands)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Product Capacity Value</label>
          <input
            value={capacityValueSelected}
            onChange={handleCapacityValue}
            className={"form-control"}
            type="text"
            maxLength={10}
          />
        </div>

        <div className="col-md-3 form-group">
          <label className="form-label">Product Capacity Unit of Measure</label>
          <Select
            onChange={handleCapacityUnitValue}
            formatOptionLabel={OptionFormatter}
            value={
              capacityUnitSelected
                ? formatCapacityUnitIntoOption(capacityUnitSelected)
                : null
            }
            options={formatCapacityUnitOptions()}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>
      </div>

      <div className="row form-row align-items-end">
        <div className="col-md-4 form-group">
          <label className="form-label">{PRODUCTION_STATUS_LABEL}</label>
          <Select
            onChange={handleProductionLifecycleStatus}
            formatOptionLabel={OptionFormatter}
            value={
              productionLifecycleStatus
                ? formatLifecycleFlagIntoOption(productionLifecycleStatus)
                : null
            }
            options={formatLifecycleFlagsOptions()}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">{SALES_STATUS_LABEL}</label>
          <Select
            onChange={handleSalesLifecycleStatus}
            formatOptionLabel={OptionFormatter}
            value={
              salesLifecycleStatus
                ? formatLifecycleFlagIntoOption(salesLifecycleStatus)
                : null
            }
            options={formatLifecycleFlagsOptions()}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>

        <div className="col-md-4 form-group">
          <label className="form-label">{LIFECYCLE_STATUS_LABEL}</label>
          <Select
            onChange={handleLifecycleStatus}
            formatOptionLabel={OptionFormatter}
            value={
              lifecycleStatusSelected
                ? formatLifecycleStatusIntoOption(lifecycleStatusSelected)
                : null
            }
            options={formatLifecycleStatusOptions(linePlanningStatusConditions)}
            classNamePrefix="react-select"
            placeholder=""
            isClearable
            components={{
              IndicatorSeparator: () => null,
              LoadingIndicator: () => null,
            }}
          />
        </div>
      </div>
    </>
  );
};
