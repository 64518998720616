import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import AuthContext from "../context/AuthContext";
import {
  getTypeOptionsBasedOnPermissions,
  isDatacatalogType,
  isGlobalProductType,
  isPackingType,
} from "../helpers";

import { DatacatalogFilterForm } from "./specific_filter_forms/datacatalog_forms/DatacatalogFilterForm";
import { PmHierFilterForm } from "./specific_filter_forms/pmhier_forms/PmHierFilterForm";
import { GpnFilterForm } from "./specific_filter_forms/gpn_tcc_forms/GpnFilterForm";
import { IGlobalProduct } from "../types/data.interface";
import {
  retrieveValueFromLocalStorageObject,
  saveObjectsToLocalStorage,
} from "../helpers/local-storage.helper";
import { GPHSearchPersistentData } from "../types/persistent-page-filters";

const GPHFilters = ({
  data,
  gps,
  // tccs,
  isLoading,
  handleTableFilter,
}: {
  data: any[];
  gps: IGlobalProduct[];
  // tccs: ITcc[];
  isLoading: boolean;
  handleTableFilter: any;
}) => {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const hasPermission = authCtx?.hasPermission;

  const [typeOptions, setTypeOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [typeSelected, setTypeSelected] = useState<any>(() =>
    retrieveValueFromLocalStorageObject<GPHSearchPersistentData>(
      "gphsearch",
      "lastTypeSelected",
      "type",
      null
    )
  );

  const [currentFilteredData, setCurrentFilteredData] = useState<any[]>([]);
  const [activeFilterForm, setActiveFilterForm] = useState<any>(null);

  const [signalToClearFilters, setSignalToClearFilters] = useState<number>(0);

  const onTypeChange = (e: any) => {
    if (e) {
      setTypeSelected({ label: e.value, value: e.value });
      setActiveFilterForm(getFormComponent(e.value));
      setCurrentFilteredData(data.filter((item) => item.type === e.value));
    } else {
      setTypeSelected(null);
      setActiveFilterForm(null);
      setCurrentFilteredData([]);
    }

    saveObjectsToLocalStorage<GPHSearchPersistentData>({
      prefix: "gphsearch",
      lastTypeSelected: { type: e ? { label: e.value, value: e.value } : null },
    });
  };

  const getFormComponent = (type: string) => {
    if (isDatacatalogType(type))
      return (
        <DatacatalogFilterForm
          data={data.filter((item) => isDatacatalogType(item.type))}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );
    if (isPackingType(type))
      return (
        <PmHierFilterForm
          data={data.filter((item) => isPackingType(item.type))}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );
    if (isGlobalProductType(type))
      return (
        <GpnFilterForm
          globalProducts={gps}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
          signalToClearFilters={signalToClearFilters}
          sendFilteredDataToTable={sendFilteredDataToTable}
        />
      );
    // if (isTccType(type))
    //   return (
    //     <TccFilterForm
    //       tccs={tccs}
    //       globalProducts={gps}
    //       typeSelected={type}
    //       setFilteredValues={setCurrentFilteredData}
    //     />
    //   );

    return null;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendFilteredDataToTable(typeSelected.value, currentFilteredData);
  };

  const sendFilteredDataToTable = (
    typeSelected: string,
    filteredData: any[]
  ) => {
    handleTableFilter(typeSelected, filteredData);
  };

  useEffect(() => {
    setTypeOptions(getTypeOptionsBasedOnPermissions(hasPermission));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (typeSelected) {
      setActiveFilterForm(getFormComponent(typeSelected.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gps, data, signalToClearFilters]);

  return (
    <div className="py-2 mb-1">
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <div className="row form-row align-items-end">
          <div className="col-md-6 form-group">
            <label className="form-label">Type</label>
            <Select
              onChange={onTypeChange}
              options={isLoading ? [] : typeOptions}
              value={typeSelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>
        {activeFilterForm}
        <div className="d-flex align-items-end mt-2 justify-content-end gap-3">
          {typeSelected?.value === "Global Product" && (
            <input
              type="button"
              className="btn btn-outline-primary"
              value="Clear Filters"
              onClick={() => {
                setSignalToClearFilters(signalToClearFilters + 1);
              }}
              disabled={!typeSelected}
            />
          )}

          <input
            type="submit"
            className="btn btn-primary"
            value="Search"
            disabled={!typeSelected}
          />
        </div>
      </form>
    </div>
  );
};

export default GPHFilters;
