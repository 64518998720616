import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IData } from "../../../types/data.interface";
import {
  formatDatacalogIntoOption,
  formatDatacatalogOptions,
} from "../../../helpers";
import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import ParentMissingTooltip from "../../ParentMissingTooltip";
import ConfirmValueModal from "../../ConfirmValueModal";
import ReactModal from "react-modal";
import Spinner from "../../Spinner";

export const DatacatalogEditCreateForm = ({
  isEdit,
  item,
  updateCreateItem,
  errors,
}: {
  isEdit: boolean;
  item: IData;
  updateCreateItem: any;
  errors: any;
}) => {
  const parentType: string = "Category";
  const { dataCatalog } = useOutletContext<any>();
  const categoryOptions = dataCatalog.filter(
    (item: IData) => item.type == parentType
  );
  const [showModal, setShowModal] = useState(false);
  const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);

  const [newItem, setNewItem] = useState<IData>(item);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [categorySelected, setCategorySelected] = useState<IData | null>(
    item.parent
  );

  const [existingItem, setExistingItem] = useState<IData | null>(null);
  const [valueWasChanged, setValueWasChanged] = useState(false);

  useEffect(() => {
    setNewItem(item);
    if (item.type === "Sub-Category") {
      setShowCategoryOptions(true);
      setCategorySelected(item.parent);
    } else {
      setShowCategoryOptions(false);
    }
  }, [item]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const existing = dataCatalog.filter(
      (item: IData) =>
        newItem.id !== item.id &&
        newItem.name === item.name &&
        newItem.type === item.type
    )[0];
    setExistingItem(existing);
    if (existing && (!isEdit || valueWasChanged)) {
      return setShowModal(true);
    } else {
      submit();
    }
  };

  const submit = async () => {
    setIsActionInProgress(true);
    const payload = {
      id: newItem.id,
      name: newItem.name,
      acronym: newItem.acronym,
      active: newItem.active,
      type: newItem.type,
      parent: newItem.parent ? newItem.parent.id : null,
      code: newItem.code,
    };
    await updateCreateItem(payload, "/data-catalog/");
    setIsActionInProgress(false);
  };

  const handleActiveCheck = () => {
    setNewItem({ ...newItem, active: !newItem?.active });
  };

  const handleAcronym = (e: any) => {
    setNewItem({ ...newItem, acronym: e.target.value.toUpperCase() });
  };

  const handleCode = (e: any) => {
    const code = parseInt(e.target.value);
    const maxCodeValue = newItem.type === "Product Line" ? 999 : 99;
    setNewItem({
      ...newItem,
      code: code > 0 && code <= maxCodeValue ? code : 0,
    });
  };

  const handleValue = (e: any) => {
    setValueWasChanged(true);
    const cleanedValue = e.target.value.replace(/[^a-z0-9- '():_&,"#/]/gi, "");
    setNewItem({ ...newItem, name: cleanedValue });
  };

  const handleCategoryValue = (e: any) => {
    if (e) {
      setCategorySelected(e.value);
      setNewItem({
        ...newItem,
        parent: e.value,
      });
    } else {
      setCategorySelected(null);
      setNewItem({
        ...newItem,
        parent: null,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ConfirmValueModal
          type={newItem.type}
          valueThatExists={newItem.name}
          newItemDescription={`${newItem?.acronym} - ${newItem?.name}`}
          existingItemDescription={`${existingItem?.acronym} - ${existingItem?.name}`}
          handleCloseModal={closeModal}
          confirmSubmit={submit}
        />
      </ReactModal>

      <form onSubmit={handleSubmit}>
        <div className="row align-items-end form-row">
          {showCategoryOptions ? (
            <>
              <div className="col-md-4 form-group">
                <label className="form-label">Hierarchy Type</label>
                <Select
                  value={{
                    label: parentType,
                    value: parentType,
                  }}
                  isDisabled
                  classNamePrefix="react-select"
                  placeholder=""
                  menuIsOpen={false}
                  isSearchable={false}
                />
              </div>
              <div className="col-md-6 form-group">
                <label className="form-label">Acronym / Value</label>
                <ParentMissingTooltip isEdit={isEdit} type={parentType} />
                <Select
                  onChange={handleCategoryValue}
                  value={
                    categorySelected
                      ? formatDatacalogIntoOption(categorySelected)
                      : null
                  }
                  options={formatDatacatalogOptions(categoryOptions)}
                  isSearchable
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  className={errors.parent ? "is-invalid" : ""}
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="row form-row">
          <div className="col-md-2 form-group">
            <label className="form-label">Code</label>
            <input
              value={newItem.code ? newItem.code : ""}
              onChange={handleCode}
              min="1"
              className={
                errors.code ? "is-invalid form-control" : "form-control"
              }
              type="number"
            />
          </div>

          <div className="col-md-6 form-group">
            <label className="form-label">Value</label>
            <input
              value={newItem.name}
              onChange={handleValue}
              className={
                errors.name ? "is-invalid form-control" : "form-control"
              }
              type="text"
            />
          </div>

          <div className="col-md-2 form-group">
            <label className="form-label">Acronym</label>
            <input
              value={newItem.acronym}
              onChange={handleAcronym}
              className={
                errors.acronym ? "is-invalid form-control" : "form-control"
              }
              type="text"
              maxLength={3}
            />
          </div>

          <div className="col-md-1 form-group d-flex flex-column justify-content-center text-center">
            <label className="form-label ">Active</label>
            <div className="fixed-checkbox-height">
              <input
                checked={newItem.active}
                onChange={handleActiveCheck}
                className="checkbox"
                type="checkbox"
              />
            </div>
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md form-group mt-4">
            <input
              type="submit"
              className="btn btn-primary"
              value="Save"
              disabled={isActionInProgress}
            />
          </div>
          {isActionInProgress && <Spinner hideLabel mediumSpinner />}
        </div>
      </form>
    </>
  );
};
