import { PlacesType, Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const GenericTooltip = ({
  tooltipId,
  message,
  place,
}: {
  tooltipId: string;
  message: string;
  place?: PlacesType;
}) => {
  return (
    <>
      <Tooltip
        id={tooltipId}
        className="tooltip-text-box"
        place={place ?? "right"}
        noArrow={true}
      />
      <span
        className="tooltip-span"
        data-tooltip-id={tooltipId}
        data-tooltip-html={message}
      >
        <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
      </span>
    </>
  );
};

export default GenericTooltip;
