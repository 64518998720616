import { getPageAcronymByType } from "../helpers";
import GenericTooltip from "./GenericTooltip";

const ParentMissingTooltip = (props: any) => {
  const tooltipMessages: any = {
    dc: {
      edit: "Sub-Category can only be associated to an existing Category. To create a new Category, please go in the Creation mode.",
      create: "Sub-Category can only be associated to an existing Category.",
    },
    pmh: {
      edit: "Packing Material Sub-Type can only be associated to an existing Packing Material Type. To create a new Packing Material Type, please go in the Creation mode.",
      create:
        "Packing Material Sub-Type can only be associated to an existing Packing Material Type.",
    },
    tcc: {
      edit: "Tupperware Consumer Code can only be associated to an existing Global Product. To create a new Global Product, please go in Creation mode.",
      create:
        "Tupperware Consumer Code can only be associated to an existing Global Product.",
    },
  };

  const editKey = props.isEdit ? "edit" : "create";
  const message = tooltipMessages[getPageAcronymByType(props.type)][editKey];
  return (
    <>
      <GenericTooltip tooltipId={props.type} message={message} />
    </>
  );
};

export default ParentMissingTooltip;
