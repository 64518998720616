import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Predicates } from "../libraries/predicates/predicates";

type GenericConfirmationModalProps = {
  message: string;
  confirmButtonMessage: string;
  cancelButtonMessage?: string;
  onConfirm: (...args: any) => any;
  onCancel?: (...args: any) => any;
  icon?: IconDefinition;
};

const GenericConfirmationModal = ({
  message,
  confirmButtonMessage,
  cancelButtonMessage,
  onConfirm,
  onCancel,
  icon,
}: GenericConfirmationModalProps) => {
  return (
    <div className="text-center p-5 m-3">
      {icon && <FontAwesomeIcon icon={icon} size="2x" className="mb-4" />}
      <p
        className="text-center mb-5"
        dangerouslySetInnerHTML={{ __html: message }}
      ></p>
      <div className="d-flex justify-content-center align-center">
        <button onClick={onConfirm} type="button" className="btn btn-primary">
          {confirmButtonMessage}
        </button>

        {Predicates.isNotNullAndNotUndefined(onCancel) &&
          Predicates.isNotNullAndNotUndefinedAndNotEmpty(
            cancelButtonMessage
          ) && (
            <button
              onClick={onCancel}
              type="button"
              className="btn btn-secondary ml-3"
            >
              {cancelButtonMessage}
            </button>
          )}
      </div>
    </div>
  );
};

export default GenericConfirmationModal;
